/**
 * Site header
 */
.site-header {
    border-bottom: 1px solid $grey-color-light;
    min-height: $header-height;

    // Positioning context for the mobile navigation icon
    position: relative;
}

#landingcontainer {
    width: 100%;
    height: 200px;
    /* padding-top: 10%; */
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right 0 top 80%;
    background-color: #00000000;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include media-query($on-palm){
        height: 100px;
    }
}

#landingcontainer {
    background-image: url(../assets/bolts.jpg);
} 



#landingcontainer a {
    color: #fff;
    font-size: 110%;
}



#landingcontainer img {
    margin: 10px auto 10px;
}

#logocontainer {
    margin: 10px auto 10px;
}

.site-title {
    font-size: 26px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    text-decoration: none;
    color: $grey-color-dark;
    &,
    &:visited {
        color: $grey-color-dark;
        text-decoration: none;
    }

    &:hover {
        color: $grey-color-dark;
        text-decoration: none;
    }
}

.light {
    display: inline;
    font-weight: 300;
}

.semibold {
    display: inline;
    font-weight: 600;
}

.site-nav {
    float: right;
    line-height: 56px;
    
    a {
        font-weight: normal;
        &:hover {
            color: darken($brand-color, 20%);
        }
    }

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        margin-left: 20px;
    }

    /* The dropdown container */
    .dropdown {
        /*float: left;
        overflow: hidden;*/
        display: inline-block;
        padding: 0;
        position:relative
    }
    
    /* Dropdown button */
    .dropdown .dropbtn {
        border: none;
        outline: none;
        background-color: inherit;
        font-family: inherit; /* Important for vertical align on mobile phones */
    }

    /* Dropdown content (hidden by default) */
    .dropdown-content {
        display: none;
        position: absolute;
        text-align: right;
        right:-10px;
        top: 48px;
        background-color: #ffffff;
        border-radius: 5px;
        min-width: 160px;
        z-index: 1;
        box-shadow: 0 8px 20px rgba(0,0,0,0.15), 0 2px 5px rgba(0,0,0,0.12);
    }
    
    /* Links inside the dropdown */
    .dropdown-content a {
        float: none;
        display: block;
        padding: 5px 10px;

        &:not(:last-child) {
            margin-right: 0;
        }
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border-radius: 5px;
        text-align: right;
        line-height: normal;

        &:hover {
            box-shadow: 0 8px 20px rgba(0,0,0,0.15), 0 2px 5px rgba(0,0,0,0.12)
        }

        .dropdown-content {
            right: 80px;
            top: 0;
        }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;
            box-shadow: none;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color-dark;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
    
    a{
        font-size: 15px;
        color: $grey-color-dark;
        font-weight: normal;
        text-decoration: none;
        
        &:hover {
        text-decoration: underline;
        }
    }
    
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(5% - (#{$spacing-unit} / 2));
    width:         calc(5% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;
    
    h1 {
        font-size: 36px;

        @include media-query($on-laptop) {
            font-size: 32px;
        }
    }
    
    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}



.example {
    padding: $spacing-unit;
    border-style: solid;
    border-width: 2px;
    margin: $spacing-unit;
    
    h1 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }
    
    h2 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h3 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    h4 {
        font-size: 18px;

        @include media-query($on-laptop) {
            font-size: 16px;
        }
    }
}

.example-block{
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    background-color: #dddddd;
}

.file-block {
    padding: 0;
    border-style: solid;
    border-width: 2px;
    margin: $spacing-unit;
    font-weight: bold;
}

.example-block-file{
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    background-color: #dddddd;
    margin: 0;
    font-weight: normal;
    border-top: 2px solid;
    border-radius: 0;
}


.info-block {
    background-color: #ccf;
    padding: 10px 40px 0 60px;
    border: 1px solid #00c;
    border-radius: 3px;
    background-image: url(../assets/info.png);
    background-repeat: no-repeat;
    background-position: 12px 10px;
    margin: 20px 0px;
}